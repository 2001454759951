<template>
  <layout>
    <template>
      <div class="common-header">
        <div class="common-back" @click="back"></div>
        <div class="common-title">
          {{ $route.meta.documentTitle }}
        </div>
        <div class="common-menu" style="opacity: 0;">
          <span></span>
        </div>
      </div>
    </template>
    <div class="list">
      <div v-for="order in orderList" :key="order.expId" class="list-item" @click="goDetail(order)">
        <div class="top-block">
          <div class="com-info">
            <img :src="order.logo" class="com-bg" />
            <span>{{ order.name }}</span>
          </div>
          <div class="date">{{ order.time }}</div>
        </div>
        <div class="list-content">
          <div class="content-item">
            <div class="loc">{{ order.sendCity }}</div>
            <div class="name">{{ order.sendName }}</div>
          </div>
          <div class="content-status">
            <img src="https://cdn.kuaidi100.com/images/m/sntInvite/icon-plane.png" class="icon-status" />
            <div class="status">{{ order.status }}</div>
          </div>
          <div class="content-item">
            <div class="loc">{{ order.recCity }}</div>
            <div class="name">{{ order.recName }}</div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
export default {
  name: "orderList",
  data() {
    return {
      sign: '',
      orderStatusMap: {
        'WAIT': '待下单',
        'ORDERING': '下单中（临时状态）',
        'SENT_SUCES': '下单成功',
        'GOT_FAIL': '揽货失败',
        'RETURN': '退签',
        'EXCEPTION': '签收异常',
        'SENT_SUCESS': '待快递员接单',
        'CHECK_WAIT': '订单待商家审核', // 待审核
        'CANCEL': '您的订单已取消', // 用户取消取件
        'SYS_CANCEL': '您的订单已取消', // 系统取消
        'RECEIVE': '待快递员接单', // 接单
        'GOTING': '待快递员取件', // 取件中
        'GOT': '快递员已取件', // 取件
        'TRANS': '在途中', // 在途
        'SIGNED': '已签收', // 签收
      },
      orderList: [],
      type: ''
    }
  },
  methods: {
    back() {
      location.replace(`${location.origin}/mexpress/sntinvite?sign=${this.sign}&type=${this.type}`)
    },
    goDetail (order) {
      location.replace(`${location.origin}/mexpress/sntinvite/orderdetail?sign=${this.sign}&expId=${order.expId}&type=${this.type}`)
      /* this.$router.push({
        name: "sntOrderDetail",
        query: {
          sign: this.sign,
          expId: order.expId
        }
      }) */
    },
    formatTime (timestamp) {
      const date = new Date(timestamp)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}.${month < 10 ? '0' + month : month}.${day < 10 ? '0' + day : day}`
    },
    getcookie(cookieName) {
      let cookieValue = ""
      if (document.cookie && document.cookie != '') {
        let cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].replace(/(^\s*)|(\s*$)/g, "");
          if (cookie.substring(0, cookieName.length + 1) == (cookieName + '=')) {
            cookieValue = unescape(cookie.substring(cookieName.length + 1))
            break
          }
        }
      }
      return cookieValue;
    },
    getOrderList () {
      const { sign } = this
      this.$http.post('/snt/order/open/exclusiveVisit.do', {
        data: {
          type: 'SNTNATIVES',
          sign,
          // token: this.getcookie('TOKEN')
        }
      }).then(res => {
        this.orderList = res.map(order => {
          const comInfo = jsoncom.company.filter(com => {
            return com.number === order.kuaidicom
          })
          return {
            name: comInfo[0].name,
            logo: `https://cdn.kuaidi100.com/images/all/56/${order.kuaidicom}.png`,
            recCity: order.recAddr ? order.recAddr.split('#')[1] : '',
            sendCity: order.sendAddr ? order.sendAddr.split('#')[1] : '',
            status: this.orderStatusMap[order.transTab],
            time: this.formatTime(order.createTime),
            ...order
          }
        }).sort((a, b) => b.createTime - a.createTime)
      })
    }
  },
  created () {
    const { sign, type } = this.$route.query
    this.sign = sign
    this.type = type
    this.login(() => {
      this.getOrderList()
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
    })
  }
}
</script>
<style lang="scss" scoped>
@mixin paddingToRem($top, $right, $bottom, $left) {
  padding: $top * 1rem $right * 1rem $bottom * 1rem $left * 1rem;
}

@mixin borderRadiusToRem($topLeft: 0, $topRight: 0, $bottomRight: 0, $bottomLeft: 0) {
  border-radius: $topLeft * 1rem $topRight * 1rem $bottomRight * 1rem $bottomLeft * 1rem;
}

@mixin flexLayout($justify: center, $align: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin font($size, $color, $lineHeight, $weight: normal) {
  font-family: 'PingFangSC-Medium';
  font-size: $size * 1rem;
  font-weight: $weight;
  line-height: $lineHeight * 1rem;
  color: $color;
  letter-spacing: 0px;
}

.list {
  @include flexLayout(flex-start, center, column);
  min-height: 100vh;
  padding-bottom: 1.5625rem;
  background-color: #f7f7f7;
  .list-item {
    width: 21.5625rem;
    margin-top: 0.625rem;
    .top-block {
      @include flexLayout(space-between);
      @include paddingToRem(0.625, 0.9375, 0.375, 0.875);
      background-color: #E0E3E6;
      box-shadow: 0 4px 17px 0 rgba(0, 34, 65, 0.04);
      @include borderRadiusToRem(0.3125, 0.3125);
      .com-info {
        @include flexLayout;
        @include font(0.9375, #001A32, 1.25);
        .com-bg {
          width: 2.03125rem;
          height: 2.03125rem;
          margin-right: 0.40625rem;
          border-radius: 50%;
        }
      }
      .date {
        @include font(0.6875, #B2BAC1, 0.8125);
      }
    }
    .list-content { 
      @include flexLayout;
      @include paddingToRem(1.0625, 0, 1.0625, 0);
      background: #fff;
      .content-item {
        flex: 1;
        @include flexLayout($direction: column);
        .loc {
          @include font(1.125, #001A32, 1.25);
        }
        .name {
          margin-top: 0.46875rem;
          @include font(0.8125, #405466, 1.25);
        }
      }
      .content-status {
        @include font(0.6875, #0A84FF, 0.8125);
        text-align: center;
        .icon-status {
          width: 7.1875rem;
          height: 1.3125rem;
          margin-top: 0.3125rem;
        }
      }
    }
  }
}

</style>